/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import * as PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { FormattedMessage } from 'react-intl'
import ServicesRoll from '../components/ServicesRoll'
import HireUs from '../components/HireUs'
import WorkRoll from '../components/WorkRoll'
import select from '../components/utils'
import menuTree from '../data/menuTree'
import Link from 'gatsby-link'

import SEO from '../components/SEO/SEO'
import { HTMLContent } from '../components/Content'

const HomePageTemplate = ({ langFix, sel }) => {
    return (
        <>
            <section className="hero is-medium">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-12 is-centered has-text-centered">
                                <h1 className="is-size-3 has-text-weight-medium is-size-3-mobile">
                                    <FormattedMessage id="home.hero.title" />
                                </h1>
                                {/* <h2 className="is-size-4 is-subtitle-color is-marginless is-font-secondary has-text-weight-normal">
                                    <FormattedMessage id="home.hero.subtitle" />
                                </h2> */}
                                <p className="is-hidden-touch has-text-weight-light mt-4 is-subtitle-color">
                                    <FormattedMessage id="home.hero.vision" />
                                </p>
                                {/* <div className="mt-5">
                                    <Link
                                        to={
                                            langFix +
                                            '/' +
                                            menuTree.contact[sel] +
                                            '/'
                                        }
                                    >
                                        <button className="button is-medium is-primary has-text-weight-medium is-outlined">
                                            <FormattedMessage id="home.hero.cta" />
                                        </button>
                                    </Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="section">
                <div className="container">
                    <div className="columns is-multiline">
                        <div className="column is-6">
                            <h3 className="is-size-1 is-size-2-mobile has-text-weight-normal is-title-color">
                                <FormattedMessage id="home.services.title" />
                            </h3>
                        </div>
                    </div>
                    <ServicesRoll />
                </div>
            </section> */}

            <section className="section">
                <div className="container">
                    <div className="column is-6 has-text-left">
                        <h3 className="is-size-3 is-size-3-mobile has-text-weight-normal is-title-color">
                            <FormattedMessage id="home.works.title" />
                        </h3>
                        <p className="has-text-weight-medium is-subtitle-color">
                            <FormattedMessage id="home.works.subtitle" />
                        </p>
                    </div>
                    <div className="mt-5">
                        <WorkRoll />
                    </div>
                </div>
            </section>

            {/* <section className="section">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column is-6" />
                        <div className="column is-5 is-centered has-text-left">
                            <h3 className="is-size-1 is-size-2-mobile has-text-weight-normal is-title-color">
                                <FormattedMessage id="home.hire.title" />
                            </h3>
                            <p className="has-text-weight-medium is-subtitle-color">
                                <FormattedMessage id="home.hire.subtitle" />
                            </p>
                            <Link
                                to={langFix + '/' + menuTree.contact[sel] + '/'}
                            >
                                <button className="button has-text-weight-medium is-medium is-primary is-outlined mt-5">
                                    <FormattedMessage id="home.hire.cta" />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="columns is-multiline">
                        <div className="column is-4 has-text-left">
                            <h3 className="is-size-1 mt-6 has-text-weight-normal is-title-color">
                                <FormattedMessage id="home.getwork.title" />
                            </h3>
                            <p className="has-text-weight-normal is-size-5 is-subtitle-color">
                                <FormattedMessage id="home.getwork.subtitle" />
                            </p>
                        </div>
                        <div className="column is-offset-2 is-6">
                            <HireUs />
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}

HomePageTemplate.propTypes = {
    helmet: PropTypes.object,
}

class HomePage extends React.Component {
    render() {
        let data
        let dataMarkdown = []
        if (this.props.data !== null) {
            dataMarkdown = this.props.data.markdownRemark
            data = this.props.data
        }
        const jsonData = data.allArticlesJson.edges[0].node.articles
        const langKey = dataMarkdown.frontmatter.lang
        const { frontmatter } = data.markdownRemark
        const langFix = langKey === 'en' ? '' : 'ka'
        const sel = select(langKey)

        return (
            <Layout
                className="content"
                data={this.props.data}
                jsonData={jsonData}
                location={this.props.location}
            >
                <SEO frontmatter={frontmatter} />
                <div>
                    <HomePageTemplate
                        imageCardSL={dataMarkdown.frontmatter.imageCardSL}
                        title={dataMarkdown.frontmatter.title}
                        main={dataMarkdown.frontmatter.main}
                        contentComponent={HTMLContent}
                        content={dataMarkdown.html}
                        langKey={langKey}
                        langFix={langFix}
                        sel={sel}
                    />
                </div>
            </Layout>
        )
    }
}

HomePage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
}

export default HomePage

export const pageQuery = graphql`
    query HomePageQuery($id: String!) {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                id
                title
                description
                lang
            }
            fields {
                slug
            }
        }
    }
`
